@use "sass:map";

$base-font: "Muli", sans-serif;
$primary-color: #1d3054;
$secondary-color: #007b90;
@import "~materialize-css/sass/materialize.scss";
@import "./ips-colors";

$sidebar-width: 17vw;

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: $base-font !important;
}

main {
  flex: 1 0 auto;
}

nav {
  background-color: white;
  color: black;
  li {
    a {
      color: black;
    }
  }
}

.active-nav {
  box-shadow: inset 0px -2px 0px 0px $primary-color;
}

.active-sidenav {
  background-color: #1d30542e;
}

.underline {
  text-decoration: underline;
  text-decoration-color: $secondary-color;
  text-underline-offset: 0.3rem;
}

form {
  .error {
    color: red;
  }
}

.dashboard-filters {
  @extend .sidenav;
  width: 100%;
  z-index: 1000;
}

.logo {
  height: 100%;
  width: $sidebar-width;
}

.ips-sidenav {
  -moz-box-shadow: -3px 0 5px 0 #555;
  -webkit-box-shadow: -3px 0 5px 0 #555;
  box-shadow: -3px 0 5px 0 #555;
  position: fixed;
  width: $sidebar-width;
  overflow-y: auto;
  height: 100vh;
  @media #{$medium-and-down} {
    display: none;
  }
  z-index: 10;
  background-color: white;
  .ips-sidenav-form {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 5rem;
  }

  .wrap {
    word-break: break-word;
  }
}

.ips-container {
  @media #{$extra-large-and-up} {
    margin-left: calc($sidebar-width + $sidebar-width/4);
    margin-right: calc($sidebar-width/4);
  }
  @media #{$large-and-up} {
    margin-left: calc($sidebar-width + $sidebar-width/8);
    margin-right: calc($sidebar-width/8);
  }
  @media #{$medium-only} {
    margin-left: calc($sidebar-width/4);
    margin-right: calc($sidebar-width/4);
  }
  margin-left: calc($sidebar-width/10);
  margin-right: calc($sidebar-width/10);
  width: auto;
  padding-top: calc($sidebar-width/10);
}

.dropdown-content {
  @extend .dropdown-content;
  max-height: 400px;
}

th.table-column {
  max-width: 100px;
}

.inline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inline-center {
  @extend .inline;
  justify-content: center;
}

strong {
  font-weight: bolder;
}

.table-container {
  overflow-x: auto;
}
.select-year::after,
.select-month::after {
  font-family: "Material Icons";
  content: "arrow_drop_down";
  position: absolute;
  top: 0.7rem;
  right: -1rem;
}
.select-month {
  margin-right: 20px;
}
.message {
  margin-bottom: 1em;
  margin-top: 0em;
}

.ips-footer {
  .container {
    @media #{$large-and-up} {
      margin-left: $sidebar-width;
      width: 100%;
      max-width: 100%;
    }
    margin-left: auto;
  }
}

.btn-tab {
  @extend .btn-flat;
  &:hover {
    color: $tabs-text-color;
  }
  &.active {
    border-bottom: 2px solid $tabs-underline-color;
    border-radius: 0;
  }
  &:focus,
  &:focus.active {
    background-color: transparentize($tabs-underline-color, 0.8);
  }
}

.flex-center {
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .valign-wrapper {
    display: inline-block;
  }
}

.help-block,
.help {
  position: relative;
  top: -10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 55%;
}

a {
  color: $secondary-color;
}

.chart-container {
  margin-bottom: 100px;
}

.beta-banner {
  &.space-above {
    margin-top: calc($sidebar-width/10);
  }
  &.absolute {
    position: absolute;
    left: 0;
    top: $navbar-height-mobile;
    @media #{$medium-and-up} {
      top: $navbar-height;
    }
  }
  background-color: color("yellow", "lighten-3");
  z-index: 30;
  text-align: center;
  padding: 10px;
  width: 100%;
  p {
    padding: 0;
    margin: 0;
  }
}

.pagination {
  .previous,
  .next {
    @extend .btn;
    @extend .btn-small;
    @extend .white;
  }
}

table thead th.asc.orderable::after {
  font-family: "Material Icons";
  content: "arrow_drop_down";
  -webkit-font-feature-settings: "liga";
}

table thead th.desc.orderable::after {
  font-family: "Material Icons";
  content: "arrow_drop_up";
  -webkit-font-feature-settings: "liga";
}

// Color Classes

$ips-red: (
  "base": #dc006a,
);

$ips-blue: (
  "base": #1d3054,
);

$ips-green: (
  "base": #007b90,
);

$ips-grey: (
  "base": #9c9c9c,
);

$ips-orange: (
  "base": #f46a25,
);

$ips-purple: (
  "base": #a285d1,
);
$ips-yellow: (
  "base": #f7e754,
);
$ips-colors: (
  "ips-red": $ips-red,
  "ips-blue": $ips-blue,
  "ips-green": $ips-green,
  "ips-grey": $ips-grey,
  "ips-orange": $ips-orange,
  "ips-purple": $ips-purple,
  "ips-yellow": $ips-yellow,
);

@each $color_name, $color in $ips-colors {
  @each $color_type, $color_value in $color {
    @if $color_type == "base" {
      .#{$color_name} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text {
        color: $color_value !important;
      }
    } @else if $color_name != "shades" {
      .#{$color_name}.#{$color_type} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text.text-#{$color_type} {
        color: $color_value !important;
      }
    }
  }
}
